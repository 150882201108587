<template>
  <div>
    <Nav />

    <div class="px-4 md:px-24 text-left pb-16">
      <h1 class="font-bold text-4xl my-8 text-center">
        Paysimple - Privacy Policy
      </h1>

      <p>
        PaySimple is committed to managing your Personal Information in line
        with global industry best practices. You can read our Privacy Policy to
        understand how we use your information and the steps we take to protect
        your information.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Disclaimers</h3>
      <p>
        WE TRY TO KEEP PAYSIMPLE AVAILABLE AT ALL TIMES, BUG-FREE AND SAFE,
        HOWEVER, YOU USE IT AT YOUR OWN RISK.
      </p>
      <br />

      <p>
        OUR WEBSITE AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY EXPRESS,
        IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT NOT LIMITED TO, ANY
        IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL
        PROPERTY RIGHTS). WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
        PaySimple MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR
        REQUIREMENTS OR THAT OUR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE,
        OR ERROR FREE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU THROUGH OUR WEBSITE OR FROM PaySimple, ITS PARENTS,
        SUBSIDIARIES, OR OTHER AFFILIATED COMPANIES, OR ITS OR THEIR SUPPLIERS
        (OR THE RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF ANY SUCH
        ENTITIES) (COLLECTIVELY, "PAYSIMPLE PARTIES") SHALL CREATE ANY WARRANTY.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Limitation of Liability</h3>
      <p>
        IN NO EVENT WILL ANY OF THE PaySimple PARTIES BE LIABLE FOR (A) ANY
        INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR (B)
        ANY DAMAGES WHATSOEVER IN EXCESS OF THE AMOUNT OF THE TRANSACTION OR
        TWENTY THOUSAND UNITED STATES DOLLARS (US$20,000.00) DOLLARS, WHICHEVER
        IS LESSER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF
        REVENUES, LOST PROFITS, LOSS OF GOODWILL, LOSS OF USE, BUSINESS
        INTERRUPTION, OR OTHER INTANGIBLE LOSSES), ARISING OUT OF OR IN
        CONNECTION WITH PaySimple’s WEBSITE OR SERVICES (INCLUDING, WITHOUT
        LIMITATION, USE, INABILITY TO USE, OR THE RESULTS OF USE OF PaySimple’s
        WEBSITES OR SERVICES), WHETHER SUCH DAMAGESARE BASED ON WARRANTY,
        CONTRACT, TORT, STATUTE, OR ANY OTHER LEGAL THEORY.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Exclusions</h3>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for certain damages.
        Accordingly, some of the above disclaimers and limitations of liability
        may not apply to you. To the extent that any PaySimple Party may not, as
        a matter of applicable law, disclaim any implied warranty or limit its
        liabilities, the scope and duration of such warranty and the extent of
        the PaySimple’s Party's liability shall be the minimum permitted under
        such applicable law.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Indemnity</h3>
      <p>
        You agree to defend, indemnify, and hold PaySimple, its officers,
        directors, employees, agents, licensors, and suppliers, harmless from
        and against any claims, actions or demands, liabilities and settlements
        including without limitation, reasonable legal and accounting fees,
        resulting from, or alleged to result from, your violation of this
        Agreement.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">
        Updates, Modifications & Amendments
      </h3>
      <p>
        We may need to update, modify or amend our Merchant Terms of Service as
        our technology evolves. We reserve theright to make changes to this
        Merchant Terms of Service at any time by giving notice to users on this
        page. <br /><br />

        We advise that you check this page often, referring to the date of the
        last modification on the page If you have any objection to any of the
        changes to this Merchant Terms of Service, you must cease using our
        website and/or servicesimmediately.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Applicable Law</h3>
      <p>
        These Terms of Use shall be interpreted and governed by the laws
        currently in force in the Federal Republic of Nigeria.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Legal Disputes</h3>
      <p>
        We shall make an effort to settle all disputes amicably. Any dispute
        arising out of this Agreement which cannot be settled, by mutual
        agreement/negotiation within 1 (one) month shall be referred to
        arbitration by a single arbitrator atthe Lagos Multi-Door Courthouse
        (“LMDC”) and governed by the Arbitration and Conciliation Act, Cap A10,
        Lawsof the Federal Republic of Nigeria. The arbitrator shall be
        appointed by both of us (we and you), where both of us are unable to
        agree on the choice of an arbitrator, the choice of arbitration shall be
        referred to the LMDC. The findings of the arbitrator and subsequent
        award shall be binding on both of us. Each of us shall bear our
        respective costs in connection with the Arbitration. Venue for the
        arbitration shall be Lagos, Nigeria.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Severability</h3>
      <p>
        f any portion of these Terms of Use is held by any court or tribunal to
        be invalid or unenforceable, either in whole or in part, then that part
        shall be severed from these Terms of Use and shall not affect the
        validity or enforceability of any other part in this Terms of Use.
      </p>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Miscellaneous</h3>
      <p>
        You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing. Assigning or
        sub-contracting any of your rights or obligations under these Terms of
        Use to any third party is prohibited. We reserve the right to transfer,
        assign or sub-contract the benefit of the whole or part of any rights or
        obligations under these Terms of Use to any third party.
      </p>
      <br />

      <h1 class="font-semibold text-4xl my-8">Acceptable Use Policy</h1>
      <p>
        By accessing or using PaySimple, you agree to comply with the terms and
        conditions of this Acceptable Use Policy.
      </p>

      <h3 class="font-semibold text-2xl mb-2">Restricted Activities</h3>
      <p>
        You may not use PaySimple in connection with any product, service,
        transaction or activity that:
      </p>
      <br />

      <ul class="list-disc">
        <li>
          violates any law or government regulation, or promotes or facilitates
          such by third parties;
        </li>
        <li>
          violates any rule or regulation of Visa, MasterCard, Verve or any
          other electronic funds transfer network (each, a “Card Network”);
        </li>
        <li>
          Is fraudulent, deceptive, unfair or predatory;
        </li>
        <li>
          causes or threatens reputational damage to us or any Card Network;
        </li>
        <li>
          involves any of the business categories listed in clause 2 below; or
        </li>
        <li>
          results in or creates a significant risk of chargebacks, penalties,
          damages or other harm or liability.
        </li>
      </ul>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Certain Business Categories</h3>
      <p>
        You may not use PaySimple in connection with any product, service,
        transaction or activity that:
      </p>
      <ul class="list-disc">
        <li>
          Falls within the Prohibition List of the Nigerian Customs
          Administration of the Federal Republic of Nigeria
        </li>
        <li>
          Relates to the sale and/or purchase of:
        </li>
        <ol class="list-decimal">
          <li>
            banned narcotics, steroids, certain controlled substances or other
            products that present a risk a consumer's safety;
          </li>
          <li>
            blood, bodily fluids or body parts;
          </li>
          <li>
            burglary tools;
          </li>
          <li>
            counterfeit items;
          </li>
          <li>
            illegal drugs and drug paraphernalia;
          </li>
          <li>
            fireworks, destructive devices and explosives;
          </li>
          <li>
            dentity documents, government documents, personal financial records
            or personal information (in any form, including mailing lists);
          </li>
          <li>
            lottery tickets, sweepstakes entries or slot machines without the
            required license
          </li>
          <li>
            offensive material or hate speech or items that promote hate,
            violence, racial intolerance, or the financial exploitation of a
            crime;
          </li>
          <li>
            police badges or uniforms
          </li>
          <li>
            chemicals
          </li>
          <li>
            recalled items
          </li>
          <li>
            prohibited services
          </li>

          <li>
            unlicensed financial services, stocks or other securities
          </li>
          <li>
            stolen property
          </li>
          <li>
            items that infringe or violate any copyright, trademark, right of
            publicity or privacy or any other proprietary right under the laws
            of any jurisdiction
          </li>
          <li>
            sales of currency without Bureau De Change license, certain
            cryptocurrency operators
          </li>
          <li>
            obscene material or pornography
          </li>
          <li>
            certain sexually oriented materials or services
          </li>
          <li>
            certain firearms, firearm parts or accessories, ammunition, weapons
            or knives
          </li>
          <li>
            any product or service that is illegal or marketed or sold in such a
            way as to create liability to PaySimp
          </li>
        </ol>
        <li>
          Relate to transactions that
        </li>

        <ol class="list-decimal">
          <li>
            show the personal information of third parties in violation of
            applicable law
          </li>
          <li>
            support pyramid or ponzi schemes, matrix programs, other "get rich
            quick" schemes or certain multi-level marketing programs
          </li>
          <li>
            are associated with purchases of annuities or lottery contracts,
            lay-away systems, off-shore banking or transactions to finance or
            refinance debts funded by a credit card
          </li>
          <li>
            pertain to ammunitions and arms; and
          </li>
          <li>
            involve gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill (whether or not it is legally defined as a lottery) and sweepstakes unless the operator has obtained prior approval from PaySimple and the operator and customers are located exclusively in jurisdictions where such activities are permitted by law.
          </li>
        </ol>
      </ul>
      <br />

      <h3 class="font-semibold text-2xl mb-2">Actions by PaySimple</h3>
      <p>
        If, in our sole discretion, we believe that you may have engaged in any violation of this Acceptable Use Policy, we may (with or without notice to you) take such actions as we deem appropriate to mitigate risk to PaySimple and any impacted third parties and to ensure compliance with this Acceptable Use Policy. Such actions may include, without limitation:
      </p><br>

      <ul>
        <li>Blocking the settlement or completion of one or more payments</li>
        <li>Suspending, restricting or terminating your access to and use of the PaySimple’s Services</li>
        <li>Terminating our business relationship with you, including termination without liability to PaySimple of any payment service agreement between you and PaySimple</li>
        <li>Taking legal action against you</li>
        <li>Contacting and disclosing information related to such violations to (i) persons who have sold/purchased goods or services from you, (ii) any banks or Card Networks involved with your business or transactions, (iii) law enforcement or regulatory agencies, and (iv) other third parties that may have been impacted by such violations or</li>
        <li>Assessing against you any fees, penalties, assessments or expenses (including reasonable attorneys’ fees) that we may incur as a result of such violations, which you agree to pay promptly upon notice.</li>
      </ul><br>

      <h3 class="font-semibold text-2xl mb-2">Updates, Modifications & Amendments</h3>
      <p>
        We may need to update, modify or amend our Acceptable Use Policy at any time. We reserve the right to make changes to this Acceptable Use Policy. <br><br>
        We advise that you check this page often, referring to the date of the last modification on the page.
      </p>


    </div>

    <Footer />
  </div>
</template>

<script>
import Nav from "../components/Nav";
import Footer from "../components/Footer";
export default {
  components: {
    Nav,
    Footer
  },
};
</script>

<style lang="scss" scoped></style>
