<template>
  <div>
    <Nav />

     <div class="px-4 md:px-24 text-left pb-16">
      <h1 class="font-bold text-4xl my-8 text-center">
            PaySimple - Terms of Use
      </h1>
      <p>
        By signing up for an account on PaySimple, SimpleCash and any of our products and services via our 
        websites and/or services, you are deemed a merchant and agree to these Merchant Terms of Service (the “Agreement”). <br><br>
        PLEASE READ THESE MERCHANT TERMS OF SERVICE CAREFULLY BEFORE SIGNING UP AS A MERCHANT. If you do not agree to any or 
        all of these Terms of Service, DO NOT USE THIS SITE!
      </p> <br>

      <h3 class="font-semibold text-2xl mb-2">About Us</h3>
      <p>
        PaySimple (“we”, “us” or “our”) is an online service that provides payment gateway services, 
        offline payment acceptance, agency banking services, digital financial services etc that enables businesses 
        process and accepts payment from their customers using various electronic channels. <br><br> Merchants can receive payments 
        from their customers via transfers, USSD, cards, direct debit etc. These funds will be settled into the merchant’s account at 
        an agreed time less an agreed transaction fee. <br><br> We are an independent contractor for all purposes, providing this 
        website and our services on an independent serviceprovider basis. We do not endorse, have control or assume the liability or 
        legality for the products or services that are paid for with our service. We do not guarantee any user’s identity and cannot 
        ensure that a buyer or seller will complete a transaction.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Agreement</h3>
      <p>
        These Merchant Terms of Service is an agreement between you and PaySimple. It details PaySimple’s obligations toyou. 
        It also highlights certain risks on using the services and you must consider such risks carefully as you will be bound 
        by the provision of this Agreement through your use of this website or any of our services.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Registration</h3>
      <p>
        To use PaySimple, you have to create a PaySimple account by registering. To register, you will provide us with 
        certain information such as your email, first name, last name, business name and phone number and we may seek 
        to verify your information, (by ourselves or through third parties), after which we will approve your account 
        unless deemed risky. You give us permission to do all these.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Change of Information</h3>
      <p>
        In the event that you change any information provided to us at registration including your business name, 
        address, financial institution, mode of payments or the products and services that you offer, or where a 
        corporate restructuring occurs you agree to notify us within 14 days of such change. We may be unable to 
        respond to you if you contact us from an address, telephone number or email account that is not registered with us
      </p><br>
      
      <h3 class="font-semibold text-2xl mb-2">Representation and Warranties</h3>
      <p>
        You represent and warrant to PaySimple that:
      </p><br>

      <ul class="list-disc">
        <li>
          you have full power and authority to enter into, execute, deliver and perform this Agreement;
        </li>
        <li>
          you are duly organised, authorised and in good standing under the laws of the Federal Republic of Nigeria or any state, region or country of your organisation and are duly authorised to do business in all other states, regions or countries in which your business operates.
        </li>
      </ul><br>

      <h3 class="font-semibold text-2xl mb-2">Age Restriction</h3>
      <p>
        Our website and services are not directed to children under 18. We do not knowingly transact or provide any services to children under 18.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Account Security</h3>
      <p>
        You agree not to allow anyone else to have or use your password details and to comply with all reasonable instructions we may issue regarding account access and security. In the event you share your password details, PaySimple will not be liable to you for losses or damages. You will also take all reasonable steps to protect the security of the personal electronic device through which you access PaySimple’s services (including, without limitation, using PIN and/or password protected personally configured device functionality to access PaySimple’s services and not sharing your device with other people).
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Data Compliance</h3>
      <p>
        You agree to comply with all data privacy and security requirements of the Payment Card Industry Data Security Standard (PCI DSS Requirements”) and under any applicable law or regulation that may be in force, enacted or adopted regarding confidentiality, your access, use, storage and disclosure of user information. Information on the PCI DSS can be found on the PCI Council’s website. It is your responsibility to comply with these standards. <br><br>
        We are responsible for the security and protection of Card Holder Data (CHD) we collect and store. Accordingly, we implement access control measures, security protocols and standards including the use of encryption and firewalltechnologies to ensure that CHD is kept safe and secure on our servers, in compliance with the PCI DSS Requirement. We also implement periodical security updates to ensure that our security infrastructures are in compliance with reasonable industry standards. <br><br>
        We acknowledge that you own all your customers’ data. You hereby grant PaySimple a perpetual, irrevocable, sub-licensable, assignable, worldwide, royalty-free license to use, reproduce, electronically distribute, and display your customers’ data for the following purposes: <br><br>
      </p>

        <ol class="list-decimal">
          <li>
            providing and improving our services;
          </li>
          <li>
            internal usage, including but not limited to, data analytics and metrics so long as individual customer data has been anonymized and aggregated with other customer data;
          </li>
          <li>
            complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with local laws; and
          </li>
          <li>
            any other purpose for which consent has been provided by your customer.
          </li>
        </ol><br>

      <h3 class="font-semibold text-2xl mb-2">Software License</h3>
      <p>
        We hereby grant you a revocable, non-exclusive, non-transferable license to use PaySimple’s APIs, developer’s toolkit, and other software applications (the “Software”) in accordance with the documentation accompanying the 
        Software. This license grant includes all updates, upgrades, new versions and replacement software for your use in connection with the PaySimple’s services. If you do not comply with the documentation and any other requirements provided by PaySimple, then you will be liable for all resulting damages suffered by you, PaySimple and third parties. Unless otherwise provided by applicable law, you agree not to alter, reproduce, adapt, distribute, display, publish, reverse engineer, translate, disassemble, decompile or otherwise attempt to create any source code that is derived from the Software. Upon expiration or termination of this Agreement, you will immediately cease all use of any Software.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Trademark License</h3>
      <p>
        We hereby grant you a revocable, non-exclusive, non-transferable license to use PaySimple’s trademarks used to identify our services (the “Trademarks”) solely in conjunction with the use of our services. You agree that you will not at any time during or after this Agreement assert or claim any interest in or do anything that may adversely affectthe validity of any Trademark or any other trademark, trade name or product designation belonging to or licensed to PaySimple (including, without limitation registering or attempting to register any Trademark or any such other trademark, trade name or product designation). Upon expiration or termination of this Agreement, you will immediately cease all display, advertising and use of all of the Trademarks.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Intellectual Property</h3>
      <p>
        We do not grant any right or license to any PaySimple intellectual property rights by implication, estoppel or otherwise other than those expressly mentioned in this Agreement. <br><br>
        Each party shall retain all intellectual property rights including all ownership rights, title, and interest in and to its own products and services, subject only to the rights and licenses specifically granted herein.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Publicity </h3>
      <p>
        You hereby grant PaySimple permissions to use your name and logo in our marketing materials including, but not limited to use on our website, in customer listings, in interviews and in press releases. Such publicity does not imply an endorsement for your products and services.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Confidential Information </h3>
      <p>
        You hereby grant PaySimple permissions to use your name and logo in our marketing materials including, but not limited to use on our website, in customer listings, in interviews and in press releases. Such publicity does not imply an endorsement for your products and services.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Know Your Customer </h3>
      <p>
        You hereby grant PaySimple permissions to use your name and logo in our marketing materials including, but not limited to use on our website, in customer listings, in interviews and in press releases. Such publicity does not imply an endorsement for your products and services.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Card Network Rules </h3>
      <p>
        Each card network has its own rules, regulations and guidelines. You are required to comply with all applicable Network Rules that are applicable to merchants. You can review portions of the Network Rules at Mastercard, Visa, Verve and other payment cards. The Card Networks reserve the right to amend the Network Rules.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Customer Payments </h3>
      <p>
        You may only process payments when authorised to do so by your customer. We will only process transactions that have been authorised by the applicable Card Network or card issuer. <br><br>
        We do not guarantee or assume any liability for transactions authorised and completed that are later reversed or charged back (see Chargebacks below). You are solely responsible for all reversed or charged back transactions, regardless of the reason for, or timing of, the reversal or chargeback. PaySimple may add or remove one or more payment types or networks at any time. If we do so we will use reasonable efforts to give you prior notice of the removal.
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Our Fees & Pricing Schedule </h3>
      <p>
        You agree to pay us for the services we render as a payment gateway for your goods and services. Our Fees will be calculated as demonstrated on the Pricing page on the website and can be calculated on the same page. The Fees on our Pricing page is integral to and forms part of this Agreement. We reserve the right to revise our Fees. <br><br>


        You are also required to maintain information and proof of service or product delivery to your customer. Where a dispute occurs needing resolution, you may be required to provide PaySimple with these.
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Publicity </h3>
      <p>
        You agree that, you are solely responsible for verifying the identities of your customers, ensuring that they are authorised to carry out the transactions on your platform, and determining their eligibility to purchase your products and services. <br><br>

        n the event that we revise our fees we will notify you within 5 days of such change.
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Payouts </h3>
      <p>
        Subject to the terms of this Agreement, PaySimple will send to your designated bank or card settlement account (“Bank Account”) all amounts settled and due to you from your transactions, minus our fees as stated in the Fee Schedule, any Reversals, Invalidated Payments, Chargebacks, Refunds or other amounts that you owe to PaySimple under this Agreement (“Payout”). If the Payout is not sufficient to cover the amounts due, you agree that we may debit your Bank Account for the applicable amounts, and/or set-off the applicable amounts against future Payouts. Upon our request, you agree to provide us with all necessary bank account and related information and grant us permission to debit amounts due from your Bank Account.
After transfer of funds is initiated to your Bank Account, we will update information on your PaySimple Dashboard to reflect settlement. Information regarding your transactions that are processed and settled using PaySimple (“Transaction History”) will be available to you when you login to your PaySimple Dashboard. While we will provide Transaction History in your PaySimple Dashboard, you are solely responsible for compiling and retaining permanent records of all transactions and other data associated with your PaySimple account as may be required for your business. PaySimple is not responsible for maintaining Transaction History or other records in a manner consistent with your record retention obligations.
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Payout Schedule </h3>
      <p>
        Your Payout Schedule, which is the time it takes us to initiate a transfer to your Bank Account settled funds from card transactions processed through us is on your PaySimple Dashboard. We reserve the right to change your PayoutSchedule, suspend payouts to your Bank Account or initiate a Reversal should we deem it necessary due to pending disputes, excessive or anticipated excessive Chargebacks or Refunds, or other suspicious activity associated with your use of PaySimple, or if required by law or court order.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">How we handle your Funds </h3>
      <p>
        You authorise and instruct PaySimple to hold, receive, and disburse funds on your behalf when such funds from your card transactions settle from the Card Networks. By accepting this Agreement, you further authorise PaySimpleon how your card transaction settlement funds should be disbursed to you as Payouts and the timing of such Payouts. <br><br>
        You agree that you are not entitled to any interest or other compensation associated with the settlement funds held by PaySimple pending settlement and Payout to your Bank Account. <br><br>

        Your authorisations will remain valid and be of full effect until your PaySimple Account is closed or terminated.
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Security and Fraud Controls </h3>
      <p>
        PaySimple is responsible for protecting the security of Payment Data including CHD in our possession and will maintain commercially reasonable administrative, technical, and physical procedures to protect all the personal information regarding you and your customers that is stored in our servers from unauthorised access and accidental loss or modification. Although, we cannot guarantee that unauthorised third parties will never be able to defeat thosemeasures or use such personal information for improper purposes. We will however take all reasonable and commercially achievable measures to address any security breach as soon as we become aware. <br><br>
        You agree to use other procedures and controls provided by us and other measures that are appropriate for your business to reduce the risk of fraud. <br><br>
        In the event that you suspect any fraudulent activity by a customer, you agree to notify PaySimple immediately and quit the delivery of the service. In addition, where we suspect that there have been frequent fraudulent transactions on your account, we reserve the right to cancel our service to you and/or your account
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Notification of Errors </h3>
      <p>
        You agree to notify us immediately any error is detected while reconciling transactions that have occurred using PaySimple. We will investigate and rectify the errors where verified. In the event that we notice any errors, we will also investigate and rectify such errors. <br><br>
        Where we owe you money as a result of such errors, we will refund the amounts owed to you by a bank transfer to your Bank Account. <br><br>
        If a transaction is erroneously processed through your platform, report to us immediately. We will investigate any such reports and attempt to rectify the errors by crediting or debiting your Bank Account as appropriate. <br><br>
        Failure to notify us within 45 (forty-five) days of the occurrence of an error will be deemed a waiver of your rights to amounts that are owed to you due to an error.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Chargebacks </h3>
      <p>
        A Chargeback usually happens when a customer files directly with or disputes through his or her credit or debit cardissuer a payment on their bill. It may result in the reversal of a transaction. You may be assessed Chargebacks for (i)customer disputes; (ii) unauthorised or improperly authorised transactions; (iii) transactions that do not comply with Card Network Rules or the terms of this Agreement or are allegedly unlawful or suspicious; or (iv) any reversals for any reason by the Card Network, our processor, or the acquiring or issuing banks. Where a Chargeback occurs, you are immediately liable for all claims, expenses, fines and liability we incur arising out of that Chargeback and agree that we may recover these amounts by debiting your Bank Account. Where these amounts are not recoverable through your Bank Account, you agree to pay all such amounts through any other means
      </p><br>


      <h3 class="font-semibold text-2xl mb-2">Reserves </h3>
      <p>
        In our sole discretion, we may place a Reserve on a portion of your Payouts by holding for a certain period such portion where we believe there is a high level of risk associated with your business. If we take such steps, we will provide you with the terms of the Reserve which may include the percentage of your Payouts to be held back, periodof time and any other such restrictions that PaySimple may deem necessary. Where such terms are changed, we will notify you. You agree that you will remain liable for all obligations related to your transactions even after the releaseof any Reserve. In addition, we may require you to keep your Bank Account available for any open settlements, Chargebacks and other adjustments. <br><br>
        To secure your performance of this Agreement, you grant PaySimple a legal claim to the funds held in the Reserve as a lien or security interest for amounts payable by you.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Refunds </h3>
      <p>
        You agree that you are solely responsible for accepting and processing returns of your products and services. We areunder no obligation to process returns of your products and services, or to respond to your customers’ inquiries about returns of your products and services. You agree to submit all Refunds for returns of your products and services that were paid for through PaySimple to your customers in accordance with this Agreement and relevant Card Network Rules.
      </p><br>

      <h3 class="font-semibold text-2xl mb-2">Termination </h3>
      <p>
        You may terminate this Agreement by closing your PaySimple Account. <br><br>
        We may suspend your PaySimple Account and your access to PaySimple services and any funds, or terminate this Agreement, if;
      </p><br>
        
        <ol class="list-decimal">
          <li>
            you do not comply with any of the provisions of this Agreement;
          </li>
          <li>
            we are required to do so by a Law;
          </li>
          <li>
            we are directed by a Card Network or issuing financial institution; or
          </li>
          <li>
            where a suspicious or fraudulent transaction occurs
          </li>
        </ol><br>
      <h3 class="font-semibold text-2xl mb-2">Restricted Activities & Acceptable Use Policy</h3>
      <p>
        You are independently responsible for complying with all applicable laws related to your use of our website and services. However, by accessing or using PaySimple, you agree to comply with the terms and conditions of our Acceptable Use Policy and are restricted from the activities specified in it which you can read on our Acceptable Use Policy page
      </p><br>







     </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "../components/Nav";
import Footer from "../components/Footer";
export default {
  components: {
    Nav,
    Footer
  },
}
</script>

<style lang="scss" scoped>

</style>